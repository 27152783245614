.quotation {
  display: flex;
  justify-content: center;
  height: 100%; }

.quotation--container {
  align-self: center;
  padding: 50px 0; }
  .image-rendering .quotation--container {
    padding: 20px 0; }

.quotation--text {
  font-family: 'Libre Baskerville', serif;
  text-align: center;
  font-size: 50px; }
  .quotation--text.is-long {
    font-size: 38px; }
  .quotation--text.is-epic {
    font-size: 28px; }
  .quotation--text.not-image-rendering {
    font-size: 28px; }
    @media (max-width: 575.98px) {
      .quotation--text.not-image-rendering {
        font-size: 22px; } }
    @media (max-width: 575.98px) {
      .quotation--text.not-image-rendering.is-long {
        font-size: 20px; } }
    @media (max-width: 575.98px) {
      .quotation--text.not-image-rendering.is-epic {
        font-size: 18px; } }
  .quotation--text ul {
    text-align: left; }

.quotation--info {
  display: flex;
  justify-content: flex-end; }

.quotation.image-rendering .quotation--author {
  margin-top: 20px;
  font-size: 1.4em; }

.quotation--link {
  padding-left: 10px; }
