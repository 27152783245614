@font-face {
  font-family: 'Montserrat';
  src: asset-url("Montserrat-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Libre Baskerville';
  src: asset-url("LibreBaskerville-Regular.ttf");
  font-weight: normal;
  font-style: normal; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background-color: #222222;
  color: #F0F0F0;
  font-family: 'Montserrat';
  line-height: 1.5;
  margin: 0;
  font-size: 15px; }

h1, h2, h3, h4, h5, h6 {
  font-family: 'Libre Baskerville', serif; }

p.for-pages {
  font-size: 18px; }

a:not(.btn) {
  color: #0ce3ac;
  text-decoration: none; }
  body.is-development a:not(.btn) {
    color: #aa35ff; }
  a:not(.btn):hover, a:not(.btn):active {
    color: #0ce3ac;
    text-decoration: underline; }
    body.is-development a:not(.btn):hover, body.is-development a:not(.btn):active {
      color: #aa35ff; }
  a:not(.btn).unflashy {
    color: #F0F0F0; }
    body.is-development a:not(.btn).unflashy {
      color: #F0F0F0; }
    a:not(.btn).unflashy:hover, a:not(.btn).unflashy:active {
      color: #F0F0F0; }
      body.is-development a:not(.btn).unflashy:hover, body.is-development a:not(.btn).unflashy:active {
        color: #F0F0F0; }
  a:not(.btn).btn {
    color: #F0F0F0; }
    body.is-development a:not(.btn).btn {
      color: #F0F0F0; }

hr {
  border-top: 1px solid #0ce3ac; }

.hidden {
  display: none !important; }

::view-transition-old(root),
::view-transition-new(root) {
  animation-duration: 0.3s; }
