.layout {
  min-height: 100vh;
  display: grid;
  grid-template-rows: [header] minmax(0, auto) [content] auto [footer] minmax(0, auto);
  align-content: space-between;
  margin: 0 auto;
  padding: 0 50px; }
  @media (min-width: 992px) {
    .layout {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    .layout {
      max-width: 1170px; } }
  @media (max-width: 575.98px) {
    .layout {
      padding: 0 20px; } }

.layout--top-menu {
  grid-row: header; }

.layout--content {
  grid-row: content; }
  .layout--content.is-backend {
    margin-bottom: 20px; }

.layout--bottom-menu {
  grid-row: footer; }
