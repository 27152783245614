.render-image {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 940px;
  min-height: 1000px;
  padding: 0 30px; }

.render-image--shared-header-footer, .render-image--header, .render-image--footer {
  width: 100%;
  text-align: center;
  padding: 20px 0;
  font-size: 1.6em; }

.render-image--header {
  border-bottom: 1px solid #0ce3ac; }

.render-image--footer {
  border-top: 1px solid #0ce3ac; }
