.button--link {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  cursor: pointer; }
  .button--link:hover {
    text-decoration: underline; }
