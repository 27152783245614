.frontend-menu {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #0ce3ac;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap; }
  body.is-development .frontend-menu {
    border-top-color: #aa35ff; }
  .frontend-menu.-pretty {
    justify-content: center; }

.frontend-menu--wrapper {
  display: flex; }

.frontend-menu--link {
  font-weight: normal;
  padding: 15px 0;
  margin: 0 20px; }
  @media (max-width: 400px) {
    .frontend-menu--link {
      margin: 0 8px; } }

.frontend-menu--wrapper > .frontend-menu--link:last-child {
  margin-right: 0; }
